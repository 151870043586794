<template>
  <div>
    <div id="checkout">
      <!-- Checkout will insert the payment form here -->
    </div>
  </div>
</template>

<script>
import { getUPOrder } from "../../api/eae";
export default {
  data() {
    return {
      user_id: "",
      amount: "",
      currency: "",
    };
  },
  mounted() {
    let order_id = this.$route.query.order_id
    getUPOrder(order_id).then((res) => {
      let year = res.data.year
      let show_year = 'Year'
      if (year > 1) {
        show_year = 'Years'
      }
      let line_items = [{
        price_data: {
          currency: "sgd",
          product_data: {
            name: 'EAE Universal Pass Subscription for ' + res.data.year + ' ' + show_year,
          },
          unit_amount: res.data.amount * 100,
        },
        quantity: 1,
      }]
      this.pay(this.$route.query.order_id, line_items);
    });
  },
  methods: {
    pay(order_id, line_items) {
      // Initialize Stripe.js
      const stripe = Stripe(
        "pk_live_51NEZe6IqAdCdAf4w9OSqGvi9anLHD6XPF1RZxtu8QBViEx2lIp9NZTztFdgf5vLVLs0Eall2KxghAfvLt8HxufuC005RNNNdUB"
      );

      initialize();
      // Fetch Checkout Session and retrieve the client secret
      async function initialize() {
        const fetchClientSecret = async () => {
          const response = await fetch(
            "https://web-backend.reachable-edu.com/Stripe/UPembedPay.php",
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                order_id: order_id,
                line_items: line_items,
              }),
            }
          );
          let result = await response.json();
          const { clientSecret } = result;
          return clientSecret;
        };

        // Initialize Checkout
        const checkout = await stripe.initEmbeddedCheckout({
          fetchClientSecret,
        });

        // Mount Checkout
        checkout.mount("#checkout");
      }
    },
  },
};
</script>

<style></style>